import { IconData } from './IconData';

export function Facebook({ width, height }: IconData) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17 17" fill="none">
            <path
                d="M14.6771 0H2.26403C1.01491 0 0 1.00107 0 2.31016V14.631C0 15.9401 1.01491 16.9412 2.26403 16.9412H8.43155V10.8578H6.87015C6.63594 10.8578 6.4798 10.7037 6.4798 10.5497V8.54759C6.4798 8.39358 6.63594 8.16257 6.79208 8.16257H8.43155V6.31444C8.43155 4.15829 9.75874 2.9262 11.7105 2.9262H13.2719C13.5061 2.9262 13.6622 3.08021 13.6622 3.31123V4.92834C13.6622 5.15936 13.5061 5.31337 13.2719 5.31337H12.3351C11.2421 5.31337 11.0079 5.85241 11.0079 6.54545V8.16257H13.35C13.5842 8.16257 13.7403 8.39358 13.7403 8.6246L13.5061 10.5497C13.5061 10.7807 13.35 10.8578 13.1157 10.8578H11.0859L11.0079 16.9412H14.6771C15.9263 16.9412 16.9412 15.9401 16.9412 14.631V2.31016C16.9412 1.00107 15.9263 0 14.6771 0Z"
                fill="#4267B2"
            />
        </svg>
    );
}
